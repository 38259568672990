import React, { useEffect, useState } from 'react';
import './ServicesCount.css'; // Import CSS file for styling
import { useNavigate } from 'react-router-dom';
import CountUp from "react-countup";
import { fetchServicesCount } from '../../../Api/v1/service/Api';

export default function ServicesCount({megaMenuInputProps,mainMenuIdInputProps,mainMenuTitleProps}) {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
        getServicesCount();
    }, []);
    const [servicesCount, setServicesCount] = useState([]);

    const getServicesCount = async () => {
        let data = await fetchServicesCount();
        if (data && data.statusCode === 200) {
            setServicesCount(data?.data);
        } else {
            setServicesCount([]);
        }
    };

    return (
        <>
            <section class="space" id="service-sec">
                <div class="container">
                    <div class="row justify-content-lg-between justify-content-center align-items-center">
                        <div class="col-lg-6 col-sm-9 pe-xl-5">
                            <div class="title-area text-center text-lg-start">
                                <span class="sub-title">
                                    <div class="icon-masking me-2">
                                        <span class="mask-icon" data-mask-src="img/theme-img/title_shape_2.svg"></span> 
                                        <img src="img/theme-img/title_shape_2.svg" alt="shape"/>
                                    </div>
                                    WHAT WE DO
                                </span>
                                <h2 class="sec-title">
                                    We Provide Exclusive Service For 
                                    <span class="text-theme fw-normal">Your Business</span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="sec-btn">
                                <a href="" onClick={(e) => {e.preventDefault();navigate("/service-listing");}} class="th-btn">
                                    VIEW ALL SERVICES
                                    <i class="fa-regular fa-arrow-right ms-2"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                    className="container"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center", // Center text alignment
                    }}
                >
                    <div className='row'>
                        <div className='col-md-3 col-sm-12 col-sm-6'>
                            <div class="service-box" style={{cursor:'pointer',height:'170px',width:'250px',padding:"40px"}}  onClick={()=>{navigate('/service-listing?filterBy=Legal')}}>
                                {/* <div class="service-box_img">
                                    <img src="https://legalprime.in/wp-content/uploads/2022/11/law.jpg" alt="Icon" style={{minHeight:'250px',maxHeight:'250px'}}/>
                                </div> */}
                                <div class="service-box_content">
                                    {/* <div class="service-box_icon">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEKIesLx9WwDPyetgbCrsh_jAVOQX2FBM_XA&s" alt="Icon" style={{minHeight:'50px',maxHeight:'50px',height:'50px'}}/>
                                    </div> */}
                                    <h3 class="box-title">
                                        Legal Service
                                    </h3>
                                    <span style={{fontSize:'35px'}}><CountUp className="CountUp" start={0} end={servicesCount.legal} delay={0.3} duration={3} /></span>
                                    {/* <a href="" class="link-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.scrollTo(0, 0);
                                            // setMegaMenu(true);
                                            megaMenuInputProps(true);
                                            mainMenuIdInputProps("64880b710efe1121de71ec22");
                                            mainMenuTitleProps("Legal")
                                            // setMenuId("64880b710efe1121de71ec22")
                                        }}>
                                        View All<i class="fas fa-arrow-right ms-2"></i>
                                    </a> */}
                                    <div class="bg-shape"><img src="img/bg/service_box_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-12 col-sm-6'>
                            <div class="service-box" style={{cursor:'pointer',height:'170px',width:'250px',padding:"40px"}}  onClick={()=>{navigate('/service-listing?filterBy=Audit')}}>
                                {/* <div class="service-box_img">
                                    <img src="https://royallionsolutions.com/wp-content/uploads/2019/07/accounting-auditing-cyprus-1.jpg" alt="Icon" style={{minHeight:'250px',maxHeight:'250px'}}/>
                                </div> */}
                                <div class="service-box_content">
                                    {/* <div class="service-box_icon">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPkBF6-gJN7tan1lNmvo9uZilt_ix2y0BK2A&s" alt="Icon" style={{minHeight:'50px',maxHeight:'50px',height:'50px'}}/>
                                    </div> */}
                                    <h3 class="box-title">
                                        Audit Service
                                    </h3>
                                    <span style={{fontSize:'35px'}}><CountUp className="CountUp" start={0} end={servicesCount.audit} delay={0.3} duration={3} /></span>
                                    {/* <a href="" class="link-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.scrollTo(0, 0);
                                            // setMegaMenu(true);
                                            megaMenuInputProps(true);
                                            mainMenuIdInputProps("64880b710efe1121de71ec22");
                                            mainMenuTitleProps("Legal")
                                            // setMenuId("64880b710efe1121de71ec22")
                                        }}>
                                        View All<i class="fas fa-arrow-right ms-2"></i>
                                    </a> */}
                                    <div class="bg-shape"><img src="img/bg/service_box_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-12 col-sm-6'>
                            <div class="service-box" style={{cursor:'pointer',height:'170px',width:'250px',padding:"40px"}}  onClick={()=>{navigate(`/service-listing?filterBy=Man Power`)}}>
                                {/* <div class="service-box_img">
                                    <img src="https://5.imimg.com/data5/AP/AX/WL/SELLER-89759537/manpower-service-provider-in-delhi-ncr.jpg" alt="Icon" style={{minHeight:'250px',maxHeight:'250px'}}/>
                                </div> */}
                                <div class="service-box_content">
                                    {/* <div class="service-box_icon">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3DfguaYUPhbToeC8hlRAI6-b2SEyDH4SEwLoj23EMG1bGKJhjiHmRL9QNdzjPHs7sf74&usqp=CAU" alt="Icon" style={{minHeight:'50px',maxHeight:'50px',height:'50px'}}/>
                                    </div> */}
                                    <h3 class="box-title">
                                        HR Service
                                    </h3>
                                    <span style={{fontSize:'35px'}}><CountUp className="CountUp" start={0} end={servicesCount.man_power} delay={0.3} duration={3} /></span>
                                    {/* <a href="" class="link-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.scrollTo(0, 0);
                                            // setMegaMenu(true);
                                            megaMenuInputProps(true);
                                            mainMenuIdInputProps("64880b710efe1121de71ec22");
                                            mainMenuTitleProps("Legal")
                                            // setMenuId("64880b710efe1121de71ec22")
                                        }}>
                                        View All<i class="fas fa-arrow-right ms-2"></i>
                                    </a> */}
                                    <div class="bg-shape"><img src="img/bg/service_box_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-12 col-sm-6'>
                            <div class="service-box" style={{cursor:'pointer',height:'170px',width:'250px',padding:"40px"}}  onClick={()=>{navigate(`/service-listing?filterBy=IT`)}}>
                                <div class="service-box_content">
                                    <h3 class="box-title">
                                        IT Solutions
                                    </h3>
                                    <span style={{fontSize:'35px'}}><CountUp className="CountUp" start={0} end={20} delay={0.3} duration={3} /></span>
                                    <div class="bg-shape"><img src="img/bg/service_box_bg.png" alt="bg"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            {/* <div className="container-count py-5">
                <h2 className="mb-4 text-center position-relative">
                    We offer a Wide Range of Services
                    <span style={{float:"right",fontSize:"16px",cursor:"pointer"}} onClick={() => {navigate("/service-listing");}}><u>View All</u></span>
                    <span className="underlineCount"></span>
                </h2>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    <div className="col" style={{ cursor: "pointer" }}>
                        <div className="card h-100 shadow service-card-count">
                            <div className="card-body">
                                <h5 className="card-title-count">Legal</h5>
                                <h6 className="card-subtitle-count mb-2 text-muted">
                                    {servicesCount.legal}
                                    <CountUp className="CountUp" start={0} end={servicesCount.legal} delay={0.3} duration={3} />
                                </h6>
                                <button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        // setMegaMenu(true);
                                        megaMenuInputProps(true);
                                        mainMenuIdInputProps("64880b710efe1121de71ec22");
                                        mainMenuTitleProps("Legal")
                                        // setMenuId("64880b710efe1121de71ec22")
                                    }}
                                    className="btn btn-primary-count text-white">
                                    View All
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col" style={{ cursor: "pointer" }}>
                        <div className="card h-100 shadow service-card-count">
                            <div className="card-body">
                                <h5 className="card-title-count">Audit</h5>
                                <h6 className="card-subtitle-count mb-2 text-muted">
                                    <CountUp className="CountUp" start={0} end={servicesCount.audit} delay={0.3} duration={3} />
                                </h6>
                                <button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        // setMegaMenu(true);
                                        // setMenuId("648807550efe118a2c71eaca")
                                        megaMenuInputProps(true);
                                        mainMenuIdInputProps("648807550efe118a2c71eaca");
                                        mainMenuTitleProps("Audit")

                                    }}
                                    className="btn btn-primary-count text-white">
                                    View All
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col" style={{ cursor: "pointer" }}>
                        <div className="card h-100 shadow service-card-count">
                            <div className="card-body">
                                <h5 className="card-title-count">Man Power</h5>
                                <h6 className="card-subtitle-count mb-2 text-muted">
                                    {servicesCount.man_power}
                                    <CountUp className="CountUp" start={0} end={servicesCount.man_power} delay={0.3} duration={3} />
                                </h6>
                                <button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        // setMegaMenu(true);
                                        // setMenuId("648810d40efe11743071ef75")
                                        megaMenuInputProps(true);
                                        mainMenuIdInputProps("648810d40efe11743071ef75");
                                        mainMenuTitleProps("Man Power")
                                    }}
                                    className="btn btn-primary-count text-white">
                                    View All
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col" style={{ cursor: "pointer" }}>
                        <div className="card h-100 shadow service-card-count">
                            <div className="card-body">
                                <h5 className="card-title-count">More Services</h5>
                                <h6 className="card-subtitle-count mb-2 text-muted">
                                    {servicesCount.more_services}
                                    <CountUp className="CountUp" start={0} end={servicesCount.more_services} delay={0.3} duration={3} />
                                </h6>
                                <button
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        // setMegaMenu(true);
                                        // setMenuId("648811090efe11ed5071efc7")
                                        megaMenuInputProps(true);
                                        mainMenuIdInputProps("648811090efe11ed5071efc7");
                                        mainMenuTitleProps("More Services")
                                    }}
                                    className="btn btn-primary-count text-white">
                                    View All
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                 {megaMenu && <UserNavigation megaMenuInput={megaMenu} mainMenuIdInput={menuId} />} 
            </div> */}
        </>
    );
}
