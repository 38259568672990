import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer/Footer";
import './css/PartnerWithUs.css';
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import { showToastErrorMsg, showToastSuccessMsg } from "../Store/toastMsg";
import { freelauncerSubmit } from "../Api/v1/leads/Api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import Select from "react-select";

const FreelancerWithUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_id: "",
    occupation: "",
    qualification: "",
    dob: "",
    gender: "",
    state: "",
    district: "",
    opted_for:[]
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = "First Name is required";
    if (!formData.last_name) newErrors.last_name = "Last Name is required";
    if (!formData.mobile_number) newErrors.mobile_number = "Mobile number is required";
    if (!formData.email_id) newErrors.email_id = "Email is required";
    if (!formData.occupation) newErrors.occupation = "Occupation is required";
    if (!formData.qualification) newErrors.qualification = "Qualification is required";
    if (!formData.dob) newErrors.dob = "DOB is required";
    else {
      // Parse the date of birth
      const dob = new Date(formData.dob);
      const today = new Date();
      
      // Calculate the age
      const age = today.getFullYear() - dob.getFullYear();
      const month = today.getMonth() - dob.getMonth();
      
      // If the person is younger than 18
      if (age < 18 || (age === 18 && month < 0)) {
        newErrors.dob = "You must be at least 18 years old";
      }
    }
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.district) newErrors.district = "District is required";
    if (!formData.opted_for.length) newErrors.opted_for = "Opted for is required";
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    { value: "legal", label: "Legal" },
    { value: "audit", label: "Audit" },
    { value: "manpower", label: "Manpower" },
    { value: "it", label: "IT (Mobile & Web App Development)" },
  ];

  const handleChange1 = (selected) => {
    setSelectedOptions(selected || []);
    const selectedValue = selected.map((option) => option.value);
    setFormData({
      ...formData,
      opted_for: selectedValue,
    });
  };
  const handleChange = (e) => {
    const { name, options } = e.target;
    if(name==='opted_for'){
      const selectedOptions = Array.from(options)
      .filter(option => option.selected)
      .map(option => option.value);
  
    setFormData({
      ...formData,
      [name]: selectedOptions,
    });
    }else{
      setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
    }
    
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    const response = await freelauncerSubmit(JSON.stringify(formData));
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setFormData({
        first_name: "",
        last_name: "",
        mobile_number: "",
        email_id: "",
        occupation: "",
        qualification: "",
        dob: "",
        gender: "",
        state: "",
        district: "",
        opted_for:[]
      });
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }

    setIsSubmitting(false);
  };
  const getIndianStates = () => [
    "Andhra Pradesh",
    "Telangana",
    "Keralam",
    "Tamil Nadu",
    "Karnataka"
  ];
  const getDistricts = (state) => {
    switch (state) {
      case "Andhra Pradesh":
        return [
          "Srikakulam",
          "Parvathipuram Manyam",
          "Vizianagaram",
          "Visakhapatnam",
          "Alluri Sitharama Raju",
          "Anakapalli",
          "Kakinada",
          "East Godavari",
          "Konaseema",
          "Eluru",
          "West Godavari",
          "NTR",
          "Krishna",
          "Palnadu",
          "Guntur",
          "Bapatla",
          "Sri Potti Sriramulu Nellore",
          "Prakasam",
          "Kurnool",
          "Nandyal",
          "Anantapuramu",
          "Sri Sathya Sai",
          "YSR",
          "Annamayya",
          "Tirupati",
          "Chittoor"
        ];
      case "Telangana":
        return [
          "Adilabad",
          "Kumuram Bheem Asifabad",
          "Mancherial",
          "Nirmal",
          "Nizamabad",
          "Jagtial",
          "Peddapalli",
          "Kamareddy",
          "Rajanna Sircilla",
          "Karimnagar",
          "Jayashankar Bhupalpally",
          "Sangareddy",
          "Medak",
          "Siddipet",
          "Jangaon",
          "Hanumakonda",
          "Warangal",
          "Mulugu",
          "Bhadradri Kothagudem",
          "Khammam",
          "Mahabubabad",
          "Suryapet",
          "Nalgonda",
          "Yadadri Bhuvanagiri",
          "Medchal–Malkajgiri",
          "Hyderabad",
          "Ranga Reddy",
          "Vikarabad",
          "Narayanpet",
          "Mahabubnagar",
          "Nagarkurnool",
          "Wanaparthy",
          "Jogulamba Gadwal"
        ];
      case "Keralam":
        return [
          "Alappuzha",
          "Ernakulam",
          "Idukki",
          "Kannur",
          "Kasaragod",
          "Kollam",
          "Kottayam",
          "Kozhikode",
          "Malappuram",
          "Palakkad",
          "Pathanamthitta",
          "Thiruvananthapuram",
          "Thrissur",
          "Wayanad"
        ];
      case "Tamil Nadu":
        return [
          "Ariyalur",
          "Chengalpattu",
          "Chennai",
          "Coimbatore",
          "Cuddalore",
          "Dharmapuri",
          "Dindigul",
          "Erode",
          "Kallakurichi",
          "Kancheepuram",
          "Kanyakumari",
          "Karur",
          "Krishnagiri",
          "Madurai",
          "Mayiladuthurai",
          "Nagapattinam",
          "Namakkal",
          "Nilgiris",
          "Perambalur",
          "Pudukkottai",
          "Ramanathapuram",
          "Ranipet",
          "Salem",
          "Sivaganga",
          "Tenkasi",
          "Thanjavur",
          "Theni",
          "Thoothukudi",
          "Tiruchirappalli",
          "Tirunelveli",
          "Tirupathur",
          "Tiruppur",
          "Tiruvallur",
          "Tiruvannamalai",
          "Tiruvarur",
          "Vellore",
          "Viluppuram",
          "Virudhunagar"
        ];
      case "Karnataka":
        return [
          "Bagalkote",
          "Bengaluru Urban",
          "Bengaluru Rural",
          "Belagavi",
          "Ballari",
          "Bidar",
          "Vijayapura",
          "Chamarajanagar",
          "Chikkaballapura",
          "Chikkamagaluru",
          "Chitradurga",
          "Dakshina Kannada",
          "Davanagere",
          "Dharwad",
          "Gadag",
          "Kalaburagi",
          "Hassan",
          "Haveri",
          "Kodagu",
          "Kolar",
          "Koppal",
          "Mandya",
          "Mysuru",
          "Raichur",
          "Ramanagara",
          "Shivamogga",
          "Tumakuru",
          "Udupi",
          "Uttara Kannada",
          "Vijayanagara",
          "Yadgiri"
        ];
      default:
        return [];
    }
  };

  return (
    <div className="home-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Become Business Associate with Us | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Join us at DALMaf Private Limited and explore the opportunities of partnering with us. We provide numerous benefits and growth opportunities for our partners." />
        <meta name="keywords" content="DALMaf, partnership, business growth, collaboration, partners" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <UserNavigation />
      <div className="container mt-5">
        <div className="row row-equal-height d-flex">
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="card form-container">
              <h2 className="section-title">Start your Business Associate Journey with us and get Benefits</h2>
              <ul className="list-group list-group-flush h-100">
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>Monthly we provide 100 to 200 business leads</li>
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>Our Legal, Audit and H.R team will help you to get business</li>
                {/* <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>You Can Earn 30K to 50K Monthly</li> */}
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>No Limitation or Contract Period</li>
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>Easily you can Do business</li>
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>You will gain expertise in overseeing corportate organization</li>
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>You can earn monthly 30,000 to 50,000 per month</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="card form-container-r">
              <div className="card-header">
                <h2 className="section-title text-center">Join Us</h2>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  {/* First Name & Last Name */}
                  <div className="row">
                    <div className="col-md-6 com-sm-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && <small className="text-danger">{errors.first_name}</small>}
                      </div>
                    </div>
                    <div className="col-md-6 com-sm-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                        {errors.last_name && <small className="text-danger">{errors.last_name}</small>}
                      </div>
                    </div>
                  </div>
                  {/* Mobile Number & Email id */}
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          name="mobile_number"
                          value={formData.mobile_number}
                          onChange={handleChange}
                        />
                        {errors.mobile_number && <small className="text-danger">{errors.mobile_number}</small>}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Id"
                          name="email_id"
                          value={formData.email_id}
                          onChange={handleChange}
                        />
                        {errors.email_id && <small className="text-danger">{errors.email_id}</small>}
                      </div>
                    </div>
                  </div>
                  {/* Occupation & Qualification */}
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          name="occupation"
                          value={formData.occupation}
                          onChange={handleChange}
                        >
                          <option value="">Select Occupation</option>
                          <option value="individual">Individual</option>
                          <option value="working">Working</option>
                          <option value="business">Business</option>
                        </select>
                        {errors.occupation && <small className="text-danger">{errors.occupation}</small>}
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          name="qualification"
                          value={formData.qualification}
                          onChange={handleChange}
                        >
                          <option value="">Select Qualification</option>
                          <option value="master_degree">Master Degree</option>
                          <option value="bachelor_degree">Bachelor Degree</option>
                          <option value="diploma">Diploma</option>
                          <option value="others">Others</option>
                        </select>
                        {errors.qualification && <small className="text-danger">{errors.qualification}</small>}
                      </div>
                    </div>
                  </div>
                  {/* Gender & DOB */}
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        {errors.gender && <small className="text-danger">{errors.gender}</small>}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Date of Birth"
                          name="dob"
                          value={formData.dob}
                          onChange={handleChange}
                          onFocus={(e) => (e.target.type = 'date')} // Change to date picker on focus
                          onBlur={(e) => (e.target.type = 'text')} // Change back on blur
                        />
                        {errors.dob && <small className="text-danger">{errors.dob}</small>}
                        {/* <DatePicker
                          selected={formData.dob}
                          onChange={handleChange}
                          placeholderText="Select your DOB"
                          dateFormat="dd/MM/yyyy"
                          className="form-control"
                        /> */}
                        {/* {errors.dob && <small className="text-danger">{errors.dob}</small>} */}
                      </div>
                    </div>
                  </div>
                  {/* State & City */}
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                        >
                          <option value="">Select State</option>
                          {getIndianStates().map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                        {errors.state && <small className="text-danger">{errors.state}</small>}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          name="district"
                          value={formData.district}
                          onChange={handleChange}
                        >
                          <option value="">Select District</option>
                          {getDistricts(formData.state).map((district, index) => (
                            <option key={index} value={district}>
                              {district}
                            </option>
                          ))}
                        </select>
                        {errors.district && <small className="text-danger">{errors.district}</small>}
                      </div>
                    </div>
                  </div>
                  {/* Submit */}
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        {/* <select
                          className="form-select"
                          name="opted_for"
                          value={formData.opted_for}
                          onChange={handleChange}
                          multiple
                        >
                          <option value="">Select</option>
                          <option value="legal">Legal</option>
                          <option value="audit">Audit</option>
                          <option value="manpower">Manpower</option>
                        </select> */}
                        <Select
                            id="opted_for"
                            // name="opted_for"
                            options={options}
                            isMulti
                            value={selectedOptions}
                            onChange={handleChange1}
                            placeholder="Select services..."
                          />
                        {errors.opted_for && <small className="text-danger">{errors.opted_for}</small>}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default FreelancerWithUs;
