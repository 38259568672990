import React, { useEffect, useState } from 'react';
import Typewriter from '../../Home/Typewriter';
import { useNavigate } from 'react-router-dom';
import styles from './home.module.css';
import investor from '../../../Assets/Images/become-investor.png'
import { showToastErrorMsg, showToastSuccessMsg } from '../../../Store/toastMsg';
import { createAPI, createWithoutTokenAPI } from '../../../Api/v1/Util/Api';
import { investors_submit_url } from '../../../Api/APIUrl';

export default function HomeBanner() {
    const navigate = useNavigate();
    const [currentText, setCurrentText] = useState('Legal Advisory');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const scrollToEarning = () => {
        document.getElementById("earning").scrollIntoView({ behavior: "smooth" });
    };

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
        document.body.classList.add('no-scroll'); // Disable background scroll
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        document.body.classList.remove('no-scroll'); // Re-enable background scroll
    };
    const images = {
        'Auditing Advisory': 'img/hero/audit1.png',
        'Human Resources': 'img/hero/hr1.png',
        'Legal Advisory': 'img/hero/legal1.png',
        'IT Service': 'img/hero/hr1.png',
        // 'Bank Auction Support': 'img/hero/buysell1.png',
        // 'Buying and Selling': 'img/hero/hero_img_3_1.png',
        // 'Marketing Assistance': 'img/hero/marketing.png'
    };

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        mobile_number: "",
        email_id: "",
        occupation: "",
        state: "",
        district: ""
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.first_name) newErrors.first_name = "First Name is required";
        if (!formData.last_name) newErrors.last_name = "Last Name is required";
        if (!formData.mobile_number) newErrors.mobile_number = "Mobile number is required";
        if (!formData.email_id) newErrors.email_id = "Email is required";
        if (!formData.occupation) newErrors.occupation = "Occupation is required";
        if (!formData.state) newErrors.state = "State is required";
        if (!formData.district) newErrors.district = "District is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsSubmitting(true);

        const response = await createWithoutTokenAPI(investors_submit_url, JSON.stringify(formData));
        if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            setFormData({
                first_name: "",
                last_name: "",
                mobile_number: "",
                email_id: "",
                occupation: "",
                state: "",
                district: ""
            });
            closeModal();
        } else {
            showToastErrorMsg(
                response.message || response.error.response.data.message
            );
        }

        setIsSubmitting(false);
    };

    const getIndianStates = () => [
        "Andhra Pradesh",
        "Telangana",
        "Keralam",
        "Tamil Nadu",
        "Karnataka"
    ];

    const getDistricts = (state) => {
        switch (state) {
            case "Andhra Pradesh":
                return [
                    "Srikakulam",
                    "Parvathipuram Manyam",
                    "Vizianagaram",
                    "Visakhapatnam",
                    "Alluri Sitharama Raju",
                    "Anakapalli",
                    "Kakinada",
                    "East Godavari",
                    "Konaseema",
                    "Eluru",
                    "West Godavari",
                    "NTR",
                    "Krishna",
                    "Palnadu",
                    "Guntur",
                    "Bapatla",
                    "Sri Potti Sriramulu Nellore",
                    "Prakasam",
                    "Kurnool",
                    "Nandyal",
                    "Anantapuramu",
                    "Sri Sathya Sai",
                    "YSR",
                    "Annamayya",
                    "Tirupati",
                    "Chittoor"
                ];
            case "Telangana":
                return [
                    "Adilabad",
                    "Kumuram Bheem Asifabad",
                    "Mancherial",
                    "Nirmal",
                    "Nizamabad",
                    "Jagtial",
                    "Peddapalli",
                    "Kamareddy",
                    "Rajanna Sircilla",
                    "Karimnagar",
                    "Jayashankar Bhupalpally",
                    "Sangareddy",
                    "Medak",
                    "Siddipet",
                    "Jangaon",
                    "Hanumakonda",
                    "Warangal",
                    "Mulugu",
                    "Bhadradri Kothagudem",
                    "Khammam",
                    "Mahabubabad",
                    "Suryapet",
                    "Nalgonda",
                    "Yadadri Bhuvanagiri",
                    "Medchal–Malkajgiri",
                    "Hyderabad",
                    "Ranga Reddy",
                    "Vikarabad",
                    "Narayanpet",
                    "Mahabubnagar",
                    "Nagarkurnool",
                    "Wanaparthy",
                    "Jogulamba Gadwal"
                ];
            case "Keralam":
                return [
                    "Alappuzha",
                    "Ernakulam",
                    "Idukki",
                    "Kannur",
                    "Kasaragod",
                    "Kollam",
                    "Kottayam",
                    "Kozhikode",
                    "Malappuram",
                    "Palakkad",
                    "Pathanamthitta",
                    "Thiruvananthapuram",
                    "Thrissur",
                    "Wayanad"
                ];
            case "Tamil Nadu":
                return [
                    "Ariyalur",
                    "Chengalpattu",
                    "Chennai",
                    "Coimbatore",
                    "Cuddalore",
                    "Dharmapuri",
                    "Dindigul",
                    "Erode",
                    "Kallakurichi",
                    "Kancheepuram",
                    "Kanyakumari",
                    "Karur",
                    "Krishnagiri",
                    "Madurai",
                    "Mayiladuthurai",
                    "Nagapattinam",
                    "Namakkal",
                    "Nilgiris",
                    "Perambalur",
                    "Pudukkottai",
                    "Ramanathapuram",
                    "Ranipet",
                    "Salem",
                    "Sivaganga",
                    "Tenkasi",
                    "Thanjavur",
                    "Theni",
                    "Thoothukudi",
                    "Tiruchirappalli",
                    "Tirunelveli",
                    "Tirupathur",
                    "Tiruppur",
                    "Tiruvallur",
                    "Tiruvannamalai",
                    "Tiruvarur",
                    "Vellore",
                    "Viluppuram",
                    "Virudhunagar"
                ];
            case "Karnataka":
                return [
                    "Bagalkote",
                    "Bengaluru Urban",
                    "Bengaluru Rural",
                    "Belagavi",
                    "Ballari",
                    "Bidar",
                    "Vijayapura",
                    "Chamarajanagar",
                    "Chikkaballapura",
                    "Chikkamagaluru",
                    "Chitradurga",
                    "Dakshina Kannada",
                    "Davanagere",
                    "Dharwad",
                    "Gadag",
                    "Kalaburagi",
                    "Hassan",
                    "Haveri",
                    "Kodagu",
                    "Kolar",
                    "Koppal",
                    "Mandya",
                    "Mysuru",
                    "Raichur",
                    "Ramanagara",
                    "Shivamogga",
                    "Tumakuru",
                    "Udupi",
                    "Uttara Kannada",
                    "Vijayanagara",
                    "Yadgiri"
                ];
            default:
                return [];
        }
    };

    return (
        <>
            <div className="th-hero-wrapper hero-3" id="hero" style={{ marginTop: '-220px' }}>
                <div className="hero-img" style={{ marginTop: '80px' }}>
                    <img src={images[currentText]} alt={currentText} style={{ height: '821px', width: '821px' }} />
                    <div className="shape-blur"></div>
                </div>
                <div className="hero-img-phone" style={{ marginTop: '140px' }}>
                    <img src={images[currentText]} alt={currentText} />
                    <div className="shape-blur"></div>
                </div>
                <div
                    className="container"
                // style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     height: "100vh", // Full screen height
                //     textAlign: "center", // Center text alignment
                // }}
                >
                    <div className="hero-style3">
                        <span className="hero-subtitle" style={{ color: "black", fontSize: '30px' }}>
                            DALMAF Your Reliable Partner
                        </span>
                        <div style={{ fontSize: "50px", fontWeight: "400", animation: "bounceInDown 2s" }}>
                            <Typewriter
                                texts={[
                                    "Auditing Advisory",
                                    "Human Resources",
                                    "Legal Advisory",
                                    "IT Service"
                                ]}
                                onTextChange={(text) => setCurrentText(text)} // Update image when new text starts typing
                            />
                        </div>
                        <p className="hero-text" style={{ color: "black", fontSize: '16px' }}>
                            At DALMAF, we deliver sustainable outcomes and solutions for your business needs through our tech-enabled and purposeful services. Our offerings span audit & assurance, advisory, consulting, and regulatory domains, ensuring your business operates compliantly and cohesively.
                        </p>
                        <div className="btn-group">
                            <a
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/schedule-meet");
                                }}
                                className="th-btn"
                            >
                                Make Appointment
                                <i className="fa-regular fa-arrow-right ms-2"></i>
                            </a>
                        </div>
                        <div className="btn-group" style={{ marginLeft: "10px" }}>
                            <a
                                href=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    scrollToEarning();
                                }}
                                className="th-btn"
                            >
                                Start Earning with Dalmaf
                                <i className="fa-regular fa-arrow-right ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="hero-shape1"><img src="img/hero/hero_shape_3_1.png" alt="shape" /></div>
            </div>
            {/* <div className="row" id="earning" style={{ padding: "30px" }}>
                <div className={`col-md-12 col-lg-6 col-sm-12`}>
                    <img src='img/new/partner-new.png' alt="Freelancer" style={{ cursor: 'pointer' }} onClick={() => { navigate('/partner-with-dalmaf') }} />
                </div>
                <div className={`col-md-12 col-lg-6 col-sm-12`}>
                Embark on your business associate journey with us and enjoy monthly benefits! We provide 100 to 200 business leads every month, supported by our dedicated Legal, Audit, and HR teams to help you grow your business effortlessly. With no limitations or contract periods, you can easily manage and expand your business while gaining valuable expertise in corporate operations. Earn a stable income of ₹30,000 to ₹50,000 per month and take control of your success today!
                </div>
                <div className={`col-md-12 col-lg-6 col-sm-12`}>
                    <img src='img/new/enterpernur-new.png' alt="Business Associate" style={{ cursor: 'pointer' }} onClick={() => { navigate('/business-associate-with-dalmaf') }} />
                </div>
            </div> */}
            <div className="row" id="earning" style={{ padding: "30px", alignItems: "center" }}>
                {/* Left Column: Text Content */}
                <div className={`col-md-12 col-lg-6 col-sm-12`} style={{ textAlign: "left", paddingRight: "20px" }}>
                    <h2 style={{ fontSize: "1.8rem", fontWeight: "bold", color: "#333", marginBottom: "20px" }}>
                        Start Your Business Associate Journey with Us
                    </h2>
                    <p style={{ fontSize: "1rem", color: "#555", lineHeight: "1.6", marginBottom: "20px" }}>
                        Embark on your business associate journey with us and enjoy monthly benefits! We provide 100 to 200 business leads every month, supported by our dedicated Legal, Audit, and HR teams to help you grow your business effortlessly. With no limitations or contract periods, you can easily manage and expand your business while gaining valuable expertise in corporate operations.
                    </p>
                    {/* <p style={{ fontSize: "1rem", color: "#555", lineHeight: "1.6", fontWeight: "bold" }}>
                        Earn a stable income of ₹30,000 to ₹50,000 per month and take control of your success today!
                    </p> */}
                </div>

                {/* Right Column: Image */}
                <div className={`col-md-12 col-lg-6 col-sm-12`} style={{ textAlign: "center" }}>
                    <img
                        src="img/new/enterpernur-new-1.png"
                        alt="Business Associate"
                        style={{
                            cursor: "pointer",
                            width: "100%",
                            maxWidth: "500px",
                            borderRadius: "8px",
                            // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                            transition: "transform 0.3s ease",
                        }}
                        onClick={() => {
                            navigate("/business-associate-with-dalmaf");
                        }}
                        onMouseEnter={(e) => e.target.style.transform = "scale(1.05)"}  // Hover effect
                        onMouseLeave={(e) => e.target.style.transform = "scale(1)"}
                    />
                </div>
            </div>
            {/* <div className='row'>
                <div className='col-md-12 col-lg-6 col-sm-12'>
                    <img src={investor} />
                </div>
                <div className='col-md-12 col-lg-6 col-sm-12'>
                    <h2 style={{ color: '#333', marginTop: '65px', fontSize: "30px" }}>Investment Benefits</h2>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item" style={{ fontSize: '20px' }}><i className="fas fa-check-circle me-2"></i><strong style={{ color: '#dc3545' }}>100% Safe Investment:</strong> Your capital is fully protected.</li>
                        <li className="list-group-item" style={{ fontSize: '20px' }}><i className="fas fa-check-circle me-2"></i><strong style={{ color: '#dc3545' }}>200+ Clients in 24 Months:</strong> Demonstrated trust and rapid growth.</li>
                        <li className="list-group-item" style={{ fontSize: '20px' }}><i className="fas fa-check-circle me-2"></i><strong style={{ color: '#dc3545' }}>High Assurance Income Guarantee:</strong> Consistent and reliable returns.</li>
                        <li className="list-group-item" style={{ fontSize: '20px' }}><i className="fas fa-check-circle me-2"></i><strong style={{ color: '#dc3545' }}>Tax-Exempt Plan:</strong> Maximize your investment benefits with tax savings.</li>
                    </ul>
                    <button className="th-btn" onClick={openModal}>
                        Know More
                    </button>
                </div>
            </div> */}
            {isModalOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#fff',
                            padding: '20px',
                            borderRadius: '8px',
                            width: '700px',
                            position: 'relative',
                        }}
                    >
                        <button
                            onClick={closeModal}
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                backgroundColor: 'transparent',
                                border: 'none',
                                fontSize: '20px',
                                cursor: 'pointer',
                            }}
                        >
                            &times;
                        </button>

                        {/* <h2 style={{ margin: '0 0 15px 0' }}>Our Team will call you back once you have submitted the form</h2> */}
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6 com-sm-12">
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="First Name"
                                            name="first_name"
                                            value={formData.first_name}
                                            onChange={handleChange}
                                        />
                                        {errors.first_name && <small className="text-danger">{errors.first_name}</small>}
                                    </div>
                                </div>
                                <div className="col-md-6 com-sm-12">
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                            name="last_name"
                                            value={formData.last_name}
                                            onChange={handleChange}
                                        />
                                        {errors.last_name && <small className="text-danger">{errors.last_name}</small>}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Mobile Number"
                                            name="mobile_number"
                                            value={formData.mobile_number}
                                            onChange={handleChange}
                                        />
                                        {errors.mobile_number && <small className="text-danger">{errors.mobile_number}</small>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email Id"
                                            name="email_id"
                                            value={formData.email_id}
                                            onChange={handleChange}
                                        />
                                        {errors.email_id && <small className="text-danger">{errors.email_id}</small>}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <select
                                            className="form-select"
                                            name="occupation"
                                            value={formData.occupation}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Occupation</option>
                                            <option value="Advocate">Advocate</option>
                                            {/* <option value="Angel Investor">Angel Investor</option> */}
                                            <option value="Chartered Accountant">Chartered Accountant</option>
                                            <option value="Company Secretary">Company Secretary</option>
                                            <option value="Cost Accountant">Cost Accountant</option>
                                            <option value="Ex-Tax Officer">Ex-Tax Officer</option>
                                            <option value="Management Team (CXO)">Management Team (CXO)</option>
                                            <option value="Retired Judge">Retired Judge</option>
                                            <option value="Tax Expert">Tax Expert</option>
                                        </select>
                                        {errors.occupation && <small className="text-danger">{errors.occupation}</small>}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <select
                                            className="form-select"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select State</option>
                                            {getIndianStates().map((state, index) => (
                                                <option key={index} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.state && <small className="text-danger">{errors.state}</small>}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <select
                                            className="form-select"
                                            name="district"
                                            value={formData.district}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select District</option>
                                            {getDistricts(formData.state).map((district, index) => (
                                                <option key={index} value={district}>
                                                    {district}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.district && <small className="text-danger">{errors.district}</small>}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                            {isSubmitting ? "Submitting..." : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}
